import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const DividerIcon = React.memo((props: iconProps) => {
    return (
        <svg width="2" height="46" viewBox="0 0 2 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="1" x2="0.999998" y2="45" stroke="#ADADAD" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
});
