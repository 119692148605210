import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const BlogIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="blog-icon"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.6666 8.44685C25.6675 8.29331 25.6381 8.1411 25.58 7.99896C25.5219 7.85681 25.4364 7.72753 25.3283 7.61852L20.3816 2.67185C20.2726 2.56372 20.1433 2.47817 20.0012 2.42012C19.859 2.36206 19.7068 2.33263 19.5533 2.33352C19.3997 2.33263 19.2475 2.36206 19.1054 2.42012C18.9632 2.47817 18.834 2.56372 18.7249 2.67185L15.4233 5.97352L2.6716 18.7252C2.56348 18.8342 2.47793 18.9635 2.41987 19.1056C2.36181 19.2478 2.33238 19.4 2.33327 19.5535V24.5002C2.33327 24.8096 2.45619 25.1063 2.67498 25.3251C2.89377 25.5439 3.19052 25.6668 3.49994 25.6668H8.4466C8.60985 25.6757 8.77315 25.6502 8.92589 25.5919C9.07864 25.5336 9.21743 25.4439 9.33327 25.3285L22.0149 12.5768L25.3283 9.33352C25.4348 9.22044 25.5215 9.0903 25.5849 8.94852C25.5962 8.85552 25.5962 8.76151 25.5849 8.66852C25.5904 8.61421 25.5904 8.55949 25.5849 8.50518L25.6666 8.44685ZM7.96827 23.3335H4.6666V20.0318L16.2516 8.44685L19.5533 11.7485L7.96827 23.3335ZM21.1983 10.1035L17.8966 6.80185L19.5533 5.15685L22.8433 8.44685L21.1983 10.1035Z"
                fill="#231F20"
            />
        </svg>
    );
});
